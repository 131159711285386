import { useEffect, useState } from "react";
import { Flex, Image, Text } from "@chakra-ui/react";

import NavBar from "../components/navBar";

import bg1 from "../assets/images/bg1.png";
import bg2 from "../assets/images/bg1Civ.png";
import hs3 from "../assets/images/hs3.png";
import aff1 from "../assets/images/affiliate/affiliate-1.png";
import aff2 from "../assets/images/affiliate/affiliate-2.png";
import aff3 from "../assets/images/affiliate/affiliate-3.png";
import aff4 from "../assets/images/affiliate/affiliate-4.png";

const affiliateSteps = [
    { image: aff1, text: "Download and Install The Zine App", fade: "fade-up" },
    { image: aff2, text: "Create an account on Zine Wallet", fade: "fade-down" },
    { image: aff3, text: "Share your unique referral link with your audience", fade: "fade-up" },
    { image: aff4, text: "Get paid when your referrals complete transactions on our platform", fade: "fade-down" },
];

const AffiliatePromotion = ({ lang, setLang }) => {
    const [iniColor, setIniColor] = useState("#fff");

    useEffect(() => {
        var myNav = document.getElementById("nav");
        window.onscroll = function () {
            if (document.body.scrollTop >= 70 || document.documentElement.scrollTop >= 70) {
                myNav.classList.add("scBg");
                setIniColor("#667085");
            } else {
                myNav.classList.remove("scBg");
                setIniColor("#fff");
            }
        };
    }, []);

    return (
        <Flex w="100%" direction="column">
            <Flex w="100%" id="nav" position="fixed" top="0" left="0" zIndex="1" direction="column">
                <NavBar {...{ lang, setLang, iniColor, affiliate: true, page: true }} />
            </Flex>

            <Flex
                w="100%"
                justify="center"
                bgColor="#6421F2"
                backgroundImage={lang === "fr" ? bg2 : bg1}
                backgroundSize="cover"
                backgroundRepeat="no-repeat"
                backgroundPosition="center center"
            >
                <Flex direction="column" className="container" px={["5", "3%"]}>
                    <Flex
                        minH={["70vh", "100vh"]}
                        w="100%"
                        pb={["50px", "130px"]}
                        direction="column"
                        align="center"
                        justify="end"
                        color="#fff"
                    >
                        <Text
                            data-aos="fade-down"
                            data-aos-duration="2000"
                            fontWeight="700"
                            fontSize={["20px", "50px"]}
                            lineHeight={["30px", "72px"]}
                            textAlign="center"
                            w={["100%", "840px"]}
                        >
                            {lang === "fr" ? "ZINE WALLET AFFILIATE PROGRAM" : "ZINE WALLET AFFILIATE PROGRAM"}
                        </Text>
                        <Text fontSize={["12px", "16px"]} align={["center", "start"]} mt="20px">
                            {lang === "fr"
                                ? "Refer and Earn Up To 1000 USDT on Zine Wallet when you refer others"
                                : "Refer and Earn Up To 1000 USDT on Zine Wallet when you refer others"}
                        </Text>
                        <Flex
                            mt={["50px", "70px"]}
                            bg="#000000"
                            transition="500ms ease-in-out"
                            _hover={{ bg: "#6421F2" }}
                            padding={["10px 15px", "10px 28px"]}
                            borderRadius="8px"
                            fontWeight="600"
                            fontSize={["12px", "16px"]}
                            justify="center"
                            align="center"
                            color="#fff"
                            cursor="pointer"
                            onClick={() => {
                                const userAgent = window.navigator.userAgent.toLowerCase();

                                if (userAgent.includes("iphone")) {
                                    window.open("https://apps.apple.com/ng/app/", "_blank");
                                } else {
                                    window.open("https://play.google.com/store/apps/", "_blank");
                                }
                            }}
                        >
                            {lang === "fr" ? "Commencer" : "Start Earning"}
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                bg="#EFF5FA"
                my="40px"
                w="100%"
                py={["40px", "60px"]}
                px={["15px", "20px"]}
                align="center"
                justify="center"
                direction={["column", "row"]}
            >
                <Flex order={["2", "1"]} mt={["20px", "0px"]} w={["auto", "35%"]} justify="center" align="center">
                    <Text borderRadius="5px" bg="#238BE1" padding={["8px 15px", "12px 28px"]} color="#fff">
                        Join Us
                    </Text>
                </Flex>
                <Text
                    order={["1", "2"]}
                    lineHeight="1.8rem"
                    data-aos="fade-up"
                    fontSize={["12px", "14px"]}
                    w={["100%", "35%"]}
                >
                    {lang === "fr"
                        ? "Be a part of our affiliate Program and unlock the potential to earn generous commissions by promoting Zine wallet in your community and across all the social media platforms you are in."
                        : "Be a part of our affiliate Program and unlock the potential to earn generous commissions by promoting Zine wallet in your community and across all the social media platforms you are in."}
                </Text>
            </Flex>

            <Flex w="100%" justify="center" bg="#F5F1FE">
                <Flex className="container" px={["5", "3%"]}>
                    <Flex w="100%" py={["8", "120px"]} direction={["column", "row"]}>
                        <Flex
                            w={["100%", "45%"]}
                            h={["350px", "auto", "600px"]}
                            borderRadius={["16px 16px 0px 0px", "16px 0px 16px 16px"]}
                            backgroundImage={hs3}
                            backgroundSize="cover"
                            backgroundRepeat="no-repeat"
                            backgroundPosition="center center"
                        ></Flex>

                        <Flex
                            flex="1"
                            bg="#895EED"
                            h="max-content"
                            borderRadius={["0px 0px 16px 16px", "0px 16px 16px 0px"]}
                            px={["5", "3", "12"]}
                            pt="8"
                            pb="20"
                            direction="column"
                            color="#fff"
                        >
                            <Text
                                data-aos="fade-down"
                                fontSize={["20px", "28px"]}
                                lineHeight={["20px", "48px"]}
                                fontWeight="bold"
                                pr={["0", "12%"]}
                            >
                                {lang === "fr"
                                    ? "Benefits of being an affiliate at Zine Wallet."
                                    : "Benefits of being an affiliate at Zine Wallet."}
                            </Text>
                            <Text data-aos="fade-up" fontSize={["12px", "16px"]} mt="7">
                                {lang === "fr"
                                    ? "Reliable Payouts: We ensure timely and consistent payout schedules so you get rewarded for your efforts without delay"
                                    : "Reliable Payouts: We ensure timely and consistent payout schedules so you get rewarded for your efforts without delay."}
                                 
                                <Text mt="6">
                                    {lang === "fr"
                                        ? "Earn Competitive Commissions: Receive up to 0.4% in USDT commission on every transaction made by users you refer."
                                        : "Earn Competitive Commissions: Receive up to 0.4% in USDT commission on every transaction made by users you refer."}
                                </Text>
                            </Text>
                        </Flex>
                    </Flex>
                </Flex>
            </Flex>

            <Flex
                wrap="wrap"
                w="100%"
                align="center"
                direction="column"
                gap={["25px", "40px"]}
                py={["40px", "60px"]}
                px="20px"
            >
                <Text fontWeight="700" fontSize={["16px", "20px"]}>
                    How It works
                </Text>
                <Flex
                    w="100%"
                    maxW="1100px"
                    wrap="wrap"
                    justify={["center", "space-between"]}
                    gap="30px"
                    align="center"
                >
                    {affiliateSteps.map((step) => (
                        <Flex direction="column" align="center" gap="20px">
                            <Image height={50} width={50} src={step.image} alt={step.text} />
                            <Text data-aos={step.fade} fontSize="14px" align="center" w={["22ch"]}>
                                {step.text}
                            </Text>
                        </Flex>
                    ))}
                </Flex>
            </Flex>
        </Flex>
    );
};

export default AffiliatePromotion;
